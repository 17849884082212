import React from "react";
import {
  Grid,
  Input,
  FormLabel,
  FormControl,
  FormErrorMessage,
  SimpleGrid,
  Text,
  Box,
  Button,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { Formik, Field, Form } from "formik";
import { useInView } from "react-intersection-observer";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const FindPlanSection = () => {
  const { ref: contactRef, inView: contactInView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });
  const breakpoints = useBreakpoint();

  const validateEmail = (value) => {
    let errorMessage;
    if (!value) {
      errorMessage = `Email is required`;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      errorMessage = "Invalid email address";
    }

    return errorMessage;
  };

  function validateRequired(value) {
    let error;
    if (!value) {
      error = `Name is required`;
    }
    return error;
  }

  const onSubmitForm = async (values) => {
    console.log(values);
  };

  return (
    <Box
      ref={contactRef}
      as="section"
      pt={{ base: 0, md: "4rem" }}
      pb="6rem"
      bg="#FFFFFF"
    >
      <Grid
        gridTemplateColumns={{ base: "none", md: "1.5fr 1fr" }}
        gridTemplateRows={{ base: "auto auto", md: "none" }}
        gridColumnGap={{ base: 0, md: "120px" }}
        gridRowGap={{ base: "24px", md: 0 }}
      >
        <Box
          position="relative"
          zIndex="4"
          height={{ base: "360px", md: "580px" }}
          backgroundImage="url('/img/insurefarm-find-plan.jpg')"
          backgroundSize="cover"
          borderRadius={{ base: "0", md: "0 8px 8px 0" }}
          mb={{ base: 0, md: 12 }}
        />
        <Box mx={{ base: "24px", md: 0 }} mr={{ base: "24px", md: "8rem" }}>
          {contactInView && (
            <motion.div
              initial={{ x: "-240px", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={
                breakpoints.md
                  ? { delay: 0.4, duration: 0.8 }
                  : { delay: 0.4, duration: 0.8 }
              }
            >
              <Text
                as="h2"
                fontWeight="bold"
                fontSize={{ base: "32px", md: "48px" }}
                lineHeight={{ base: "48px", md: "64px" }}
                color="#333333"
                mb={{ base: 4, md: 6 }}
              >
                Get a free
                <br />
                no-obligation quote
              </Text>
              <Text
                fontSize="20px"
                lineHeight="32px"
                as="p"
                color="#333333"
                mb="8"
              >
                View medicare plans and rates from the top carriers in your
                area.
              </Text>
            </motion.div>
          )}
          {contactInView && (
            <motion.div
              initial={{ x: "20vw", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={
                breakpoints.md
                  ? { delay: 0.8, duration: 0.8 }
                  : { delay: 0.4, duration: 0.8 }
              }
            >
              <Formik
                initialValues={{
                  name: undefined,
                  email: undefined,
                  message: undefined,
                }}
                onSubmit={(values, actions) => {
                  onSubmitForm(values, actions);
                }}
                validateOnChange
              >
                {(_) => (
                  <Form>
                    <SimpleGrid
                      rows={{ sm: 2, lg: 1 }}
                      columns={{ sm: 1, lg: 2 }}
                      columnGap={4}
                    >
                      <Field name="firstName" validate={validateRequired}>
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.firstName && form.touched.firstName
                            }
                            mb={4}
                          >
                            <FormLabel
                              htmlFor="firstName"
                              className="pm-form-label"
                            >
                              First Name
                            </FormLabel>
                            <Input
                              {...field}
                              id="firstName"
                              borderRadius="4px"
                              bg="white"
                              size="lg"
                            />
                            <FormErrorMessage>
                              {form.errors.firstName}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="lastName" validate={validateRequired}>
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.lastName && form.touched.lastName
                            }
                            mb={4}
                          >
                            <FormLabel
                              htmlFor="lastName"
                              className="pm-form-label"
                            >
                              Last Name
                            </FormLabel>
                            <Input
                              {...field}
                              id="lastName"
                              borderRadius="4px"
                              bg="white"
                              size="lg"
                            />
                            <FormErrorMessage>
                              {form.errors.lastName}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </SimpleGrid>

                    <Field name="email" validate={validateEmail}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.email && form.touched.email}
                          mb={4}
                        >
                          <FormLabel htmlFor="email" className="pm-form-label">
                            Email
                          </FormLabel>
                          <Input
                            borderRadius="4px"
                            bg="white"
                            type="email"
                            {...field}
                            id="email"
                            size="lg"
                          />
                          <FormErrorMessage>
                            {form.errors.email}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Box mt="8">
                      <Button
                        colorScheme="primary"
                        borderRadius="4px"
                        boxShadow="none"
                        p="24px"
                      >
                        FIND PLAN
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </motion.div>
          )}
        </Box>
      </Grid>
    </Box>
  );
};

export default FindPlanSection;
