import React from "react";
import Layout from "../components/Layout";
import { Box } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import FindPlanSection from "../components/FindPlanSection";

const FindPlanPage = ({ pathname }) => {
  return (
    <Layout pathname={pathname}>
      <Helmet>
        <title>Insure Farm | Find Plan</title>
        <meta
          name="description"
          content="The first step to deciding the best healthcare option for you is to understand the basics about Medicare."
        />
      </Helmet>
      <Box>
        <FindPlanSection />
      </Box>
    </Layout>
  );
};

export default FindPlanPage;
